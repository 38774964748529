'use client'

import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import qs from 'qs';
import logoAnimated from "../assets/img/logo-single-animated.svg";

export default function ModalPromoLink(props:any) {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const filename = ( props.filename !== undefined ? props.filename : "" );
    const uuid = ( props.uuid !== undefined ? props.uuid : "" );
    const api_url = ( props.api_url !== undefined ? props.api_url : "" );
    const cdn_url = ( props.cdn_url !== undefined ? props.cdn_url : "" );
    const url = ( props.url !== undefined ? props.url : "" );

    const onClick = () => {
        setModalOpen(true);
    }

    const getDigimLink = async() => {
        const requestOptions:any = {
            //mode: 'no-cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                (
                    url !== "" && cdn_url !== ""
                    ?
                        {
                            cdn_url: cdn_url,
                            url: url
                        }
                    :
                        {
                            request: "find",
                            layout: "promodata",
                            promodataUUID: uuid
                        }
                )
            )
        };
        const response = await fetch(api_url + ( url !== "" && cdn_url !== "" ? "?prepare=download" : "" ), requestOptions);

        //console.log(requestOptions);
        //console.log(response);

        const data = await response.json();
        if( data.status === 200 ) {
            const query = qs.stringify({download:filename});
            const element = document.createElement("a");
            element.href = api_url + '?' + query;
            document.body.appendChild(element);
            element.click();

            document.getElementById("loading")?.classList.add("hidden");
            setModalOpen(false);
        }
    }

    return (
        <>
        <div className="relative cursor-pointer" data-promo={ uuid } onClick={ onClick } aria-label="Download file">
            { props.children }
        </div>
        <Transition show={ modalOpen } as={ Fragment } afterEnter={ getDigimLink }>
            <Dialog onClose={() => setModalOpen(false)}>
                {/* Modal backdrop */}
                <Transition.Child
                    className="fixed inset-0 z-[99999] bg-black bg-opacity-50 transition-opacity"
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-out duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    aria-hidden="true"
                />
                {/* End: Modal backdrop */}

                {/* Modal dialog */}
                <Transition.Child
                    className="fixed inset-0 z-[99999] flex px-4 md:px-6 py-6"
                    enter="transition ease-out duration-300"
                    enterFrom="opacity-0 scale-75"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-out duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-75"
                >
                <div className="max-w-full md:max-w-5xl mx-auto h-full flex items-center">
                    <Dialog.Panel className="w-full max-h-full aspect-video bg-black bg-transparent min-h-[200px] overflow-hidden">
                        <div id="loading" className="text-center p-6 mb-6" style={{width:200, height: 350}}>
                            <img
                                src={ logoAnimated.src }
                                className="object-contain w-full h-auto"
                            />
                        </div>
                    </Dialog.Panel>
                </div>
                </Transition.Child>
                {/* End: Modal dialog */}
            </Dialog>
        </Transition>
        </>
    )
}